<template>
  <header class="site-header site-header--menu-left site-header--absolute">
    <div class="container">
      <nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0">
        <!-- Brand Logo-->
        <div class="navbar-top d-flex justify-content-between">
          <!-- <div id="google_translate_element"></div> -->
          <div class="brand-logo">
            <router-link :to="baseurl">
              <!-- light version logo (logo must be black)-->
              <!--<h3>Interactive <span>Intro</span></h3>-->
              <!--  <img class="nav-logo" :src="users.logo" />-->
              <img class="nav-logo" src="@/assets/front/image/lets-intro-logo (2).png" />
            </router-link>
          </div>
          <ul class="navbar-nav">
            <li v-if="this.userRole == 1" class="DashboardButton m-d-none">
              <a href="/dashboard" class="btn btn-primary add-comman-btn" style="font-size: 12px;" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Admin Panel</a>
            </li>
            <li class="m-d-none">
              <router-link to="/insider" class="btn btn-primary add-comman-btn" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Intro Insider</router-link>
            </li>
            <li class="nav-item country-select m-d-none">
              <select name="accountMenuOption" @change="onchange()" class="sign_in" v-model="key">
                <option v-for="(option) in options" v-bind:key="option.name" v-bind:value="option.slug">
                  {{ option.name }}
                </option>
                //console.log(index);
              </select>
            </li>
            <li class="nav-item m-d-none">
              <!-- <form class="search_form" v-on:submit.prevent="submit"> -->
              <!-- <button type="submit" class="search_toggle">
                  <img src="@/assets/front/image/svg/search.svg" />
                </button> -->
              <!-- <a href="/search?search=" class="search_toggle"><img src="@/assets/front/image/svg/search.svg" /></a> -->
              <router-link :to="'/search?search='" class="search_toggle"><img
                  src="@/assets/front/image/svg/search.svg" /></router-link>
              <form class="search_form" v-on:submit.prevent="submit">
                <a href="#" class="hide_form"><i class="fa fa-times"></i></a>
                <input type="text" placeholder="Search by place, name & more" v-model="name" class="form-control" />
                <button type="submit">
                  <img src="@/assets/front/image/svg/search.svg" />
                </button>
              </form>
            </li>
          </ul>
          <div class="header-btn">
            <a href="#" class="hide_menu"><i class="fa fa-times"></i></a>
            <!-- <a href="#" class="sign_in">Dubai &nbsp;<i class="fa fa-angle-down"></i></a>--->

            <router-link :to="{ name: 'front-blogs' }" class="nav-lin m-d-none">Blog</router-link>

            <template v-if="user.loggedIn">
              <router-link :to="{ name: 'userProfile' }" class="sign_in m-d-none" id="profile_tag">
                <!-- <div>{{ user.data.displayName }}</div> -->
                <div>{{ firstname }}</div>
                <div class="profile_name">Profile</div>
              </router-link>
              <!-- <router-link to="/" class="sign_in"></router-link> -->
              <a class="nav-link m-d-none" @click.prevent="signOut">Sign out</a>
            </template>
            <template v-else>
              <router-link to="/signin" class="sign_in m-d-none">Sign in </router-link>
              <router-link to="/registration" class="sign_up m-d-none">Sign up </router-link>
            </template>

            <router-link to="/cart" class="cart-icon">
              <img class="shoping-card-icon" src="@/assets/front/image/shopping-cart.png" />
              <span>{{ this.count }}</span>
            </router-link>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-toggleable-sm m-menu">
        <button class="navbar-toggler collapsed" @click="popup($event)" type="button" data-toggle="collapse" data-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span> </span>
          <span> </span>
          <span> </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <div class="brand-logo">
              <router-link :to="baseurl">
                <!-- light version logo (logo must be black)-->
                <!--<h3>Interactive <span>Intro</span></h3>-->
                <!--  <img class="nav-logo" :src="users.logo" />-->
                <img class="nav-logo" src="@/assets/front/image/main-logo.png" />
              </router-link>
            </div>
            <li>
              <!-- <div id="google_translate_element"></div> -->
            </li>
            <li class="nav-item country-select">
              <select name="accountMenuOption" @change="onchange()" class="sign_in" v-model="key">
                <option v-for="(option) in options" v-bind:key="option.name" v-bind:value="option.slug">
                  {{ option.name }}
                </option>
              </select>
            </li>
            <li class="nav-item">
              <!-- <div class="search-container">
                <input type="text" placeholder="Search...">
                <button type="submit"><img src="/img/search.5bc867da.svg"></button>
              </div> -->
              <!-- <router-link :to="'/search?search='" class="search_toggle"><img
                  src="@/assets/front/image/svg/search.svg" /></router-link> -->
              <form class="" v-on:submit.prevent="submit">
                <input type="text" placeholder="Search by place, name & more" v-model="name" class="search_input" />
                <button type="submit"  class="search_button">
                  <img src="@/assets/front/image/svg/search.svg" />
                </button>
              </form>
            </li>
            <li class="nav-item">
              <router-link to="/insider" data-aos="fade-up"
                data-aos-duration="1200" data-aos-delay="400">Intro Insider</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'front-blogs' }" class="nav-link">Blog</router-link>
            </li>
            <div v-if="user.loggedIn">
              <li class="nav-item">
                <router-link :to="{ name: 'userProfile' }" class="sign_in" id="profile_tag">
                  <!-- <div>{{ user.data.displayName }}</div> -->
                  <!-- <div>{{ firstname }}</div> -->
                  <div>Profile</div>
                </router-link>
                </li>
                <li  class="nav-item">
                <a @click.prevent="signOut" class="sign_out">Sign out</a>
              </li>
            </div>
            <div v-else>
              <li class="nav-item">
                <router-link to="/signin" class="sign_in">Sign in </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/registration" class="sign_up">Sign up </router-link>
              </li>
            </div>
          </ul>
        </div>
      </nav>
    </div>

  </header>
  <!-- Heade End -->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from "vuex";
import firebase from "firebase";
import { db } from "@/main";
import { initialAbility } from '@/libs/acl/config'
import store from '@/store';

// import vGoogleTranslate from "v-google-translate";
// Vue.use(vGoogleTranslate);


export default {
  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      user: "user",
    }),
  },
  data: function () {
    return {
      options: [],
      users: {},
      loggedIn: '',
      userRole: '',
      name: "",
      count: "",
      counts: "",
      accountMenuOption: "/",
      key: localStorage.getItem("slug"),
      baseurl: "/en/" + localStorage.getItem("slug"),
    };
  },
  validations: {
    name: {},
  },
  created: function () {
    this.firstname = localStorage.getItem("userFirstname");
    // alert('firstname')
    document.getElementById("google_translate_element").style.display = "block";
    this.accountMenuOption = this.$router.history.current.fullPath;
    this.loggedIn = localStorage.getItem("userSessionId") || false;
    this.getCountries();
    // this.getCartCount(localStorage.getItem("slug_id"));
    this.getUser();
  },

  watch: {
    // Watch for route changes
    $route(route) {
      // Change the value of the selected option
      this.accountMenuOption = route.path;
      console.log('page 166');
      console.log(route);
      // alert('arbaz')
      // this.getCartCount(localStorage.getItem("slug_id"));
    },
  },

  mounted() {
    // alert('lastname')
    // this.$router.go();
    this.getCartCount(localStorage.getItem("slug_id"));
  },

  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit: function () {
      console.log(this.name);
      this.$router.push("/search?search=" + this.name);
    },

    onchange: function () {
      db.collection("countries")
        // .where("status", "==", "1")
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            if (doc.data().status == 1) {
              var slug = doc.data().country.replace(/\s+/g, '-').toLowerCase();
              // console.log(slug)
              if (slug == localStorage.getItem("slug")) {

                localStorage.setItem("sluga", doc.data().lng);
                localStorage.setItem("slugaa", doc.data().lat);
                localStorage.setItem("ciddata", doc.id);
                localStorage.setItem("slug_id", doc.id);
                this.getCartCount(doc.id);

              }
            }

          });
          // this.getCartCount(localStorage.getItem("slug_id"));
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
      console.log("page 1");
      console.log(this.key);
      localStorage.setItem("slug", this.key);
      this.baseurl = "/en/" + this.key;
      this.$router.push(this.baseurl);
      // alert(localStorage.getItem("slug_id"))
      // this.getCartCount(localStorage.getItem("slug_id"));
      //document.getElementById("map").innerHTML.reload
      // alert(window.location.href);
      let text = window.location.href;
      let result = text.includes("?search=");
      if (result) {
        //this.$router.replace("/?search=")
        // location.reload();

      }
    },

    async getCartCount(slug_id) {
      // alert(slug_id)
      var uid = localStorage.getItem("userSessionId");
      var randomId = localStorage.getItem("randomId");
      var slug = localStorage.getItem("slug_id");
      console.log('uid=>', uid, 'randomId=>', randomId, 'slug_id=>', slug)

      if (uid != null) {
        console.log("yes1");
        console.log(uid);
        this.query = db.collection("cart").where("uid", "==", uid).where("slug", "==", slug);
        this.querys = db.collection("orders").where("uid", "==", uid);
      } else {
        console.log("no1");
        this.query = db.collection("cart").where("random_id", "==", randomId).where("slug", "==", slug);
      }

      const snapshot = await this.query.get();
      this.count = snapshot.size;

      console.log(this.count);

      const snapshots = await this.querys.get();
      this.counts = snapshots.size;
      console.log(this.counts);

      // if(counts > 0){
      //  console.log('rrrr');
      //   console.log(counts);
      // }
    },

    getCountries() {

      db.collection("countries")
        // .where("status", "==", 1)
        .get()
        .then((querySnapshot) => {
          //console.log('hhhh1');
          querySnapshot.forEach((doc) => {
            if (doc.data().status == 1) {
              // console.log(doc.data())
              var slug = doc.data().country.replace(/\s+/g, '-').toLowerCase();
              // console.log(slug)
              if (slug == localStorage.getItem("slug")) {

                localStorage.setItem("sluga", doc.data().lng);
                localStorage.setItem("slugaa", doc.data().lat);
                localStorage.setItem("ciddata", doc.id);
              }
            }
            //console.log(doc.id, " => ", doc.data());
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });

      this.optionsData = sessionStorage.getItem("optionsData");
      console.log(this.optionsData == null)
      if (this.optionsData == null) {

        this.options = [];
        this.optionsData = [];

        db.collection("countries")
          .orderBy('country', 'asc')
          .get()
          .then((querySnapshot) => {
            //console.log('hhhh1');
            querySnapshot.forEach((doc) => {
              db.collection("companies")
                .where("country", "==", doc.id)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((docs) => {
                    var slug = doc.data().country.replace(/\s+/g, '-').toLowerCase();
                    if (doc.data().status == 1) {
                      this.optionsData.push({
                        id: doc.id,
                        name: doc.data().country,
                        slug: slug,
                      });
                      // localStorage.setItem("optionsData", this.optionsData);
                      const ids = this.optionsData.map(o => o.id)
                      this.options = this.optionsData.filter(({ id }, index) => !ids.includes(id, index + 1))

                      // localStorage.setItem("optionsData", JSON.stringify(this.options));
                      sessionStorage.setItem('optionsData', JSON.stringify(this.options));
                      console.log(this.options)
                    }
                  });
                });
              // console.log(this.options);

              db.collection("subscriptions")
                .where("country", "==", doc.id)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((docsub) => {
                    var slug = doc.data().country.replace(/\s+/g, '-').toLowerCase();
                    if (doc.data().status == 1) {
                      this.optionsData.push({
                        id: doc.id,
                        name: doc.data().country,
                        slug: slug,
                      });
                      // localStorage.setItem("optionsData", this.optionsData);
                      const ids = this.optionsData.map(o => o.id)
                      this.options = this.optionsData.filter(({ id }, index) => !ids.includes(id, index + 1))

                      // localStorage.setItem("optionsData", JSON.stringify(this.options));
                      sessionStorage.setItem('optionsData', JSON.stringify(this.options));
                      console.log(this.options)
                    }
                  });
                });
            });
            //console.log(doc.id, " => ", doc.data());
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      } else {
        this.options = JSON.parse(sessionStorage.getItem("optionsData"));
        // console.log(JSON.parse(this.options))
      }
    },
    getUser() {
      var uid = localStorage.getItem("userSessionId");
      // alert(uid);
      if (uid) {
        db.collection("users")
          .doc(uid)
          .get()
          .then((doc) => {
            console.log(doc.data());
            //console.log(doc.id, " => ", doc.data());
            // this.firstname = doc.data().firstname;
            this.users = doc.data();
            this.userRole = doc.data().status;
            console.log('Users' + this.users);

          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
      }
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // localStorage.removeItem(isloggedIn);
          localStorage.removeItem('Ustatus');
          localStorage.removeItem("userSessionId");
          localStorage.removeItem("randomId");
          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Reset ability
          this.$ability.update(initialAbility)

          store.dispatch('logOut');
          console.log(store.getters.user)
          console.log('logout');

          // Redirect to login page
          this.$router.push({ name: 'Home' })
        })

    },

    popup(event) {
      var SideMenu = document.getElementsByClassName("navbar-collapse collapse show");

      if(!SideMenu.navbarNav){
        document.getElementById("google_translate_element").style.display="none"
        document.getElementById("top_filter").style.display="none"
      }else{
        document.getElementById("google_translate_element").style.display="block"
        document.getElementById("top_filter").style.display="block"
      }
    },
  },

};
</script>

<style>
#profile_tag {
  flex-direction: column;
}

.profile_name {
  color: #6e6b7b;
  font-size: 10px;
}

@import "../../assets/front/css/bootstrap.css";

@import "../../assets/front/fonts/icon-font/css/style.css";

@import "../../assets/front/fonts/typography-font/typo.css";

@import "../../assets/front/fonts/fontawesome-5/css/all.css";

@import "../../assets/front/plugins/aos/aos.min.css";

@import "../../assets/front/plugins/fancybox/jquery.fancybox.min.css";

@import "../../assets/front/plugins/nice-select/nice-select.min.css";

@import "../../assets/front/plugins/slick/slick.min.css";

@import "../../assets/front/plugins/date-picker/css/gijgo.min.css";

@import "../../assets/front/css/main.css";

@import "../../assets/front/css/languageHeader.css";

@media screen and (max-width: 576px) {
  #google_translate_element {
    z-index: 999 !important;
    left: 0.7rem !important;
    position: absolute !important;
    top: 75px !important;
  }

  .goog-te-combo {
    font-size: 10px !important;
  }

  .header-btn {
    /* margin-left: 64px !important; */
    padding-left: 50% !important;
  }

  a.nav-link {
    left: 5% !important;
  }
}

.DashboardButton {
  margin-left: -12px;
}

.button_wraper {
  /* / background: #03e1bc !important; / */
  display: inline-block;
  width: 160px;
  text-align: center;
  border-radius: 50px !important;
  height: 50px;
  /* / line-height: 30px; / */
}

/* parth css */
@media (max-width: 767px) {
  .navbar-collapse {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 20em;
    height: 100vh;
    box-shadow: 5px 0 0 #000;
  }

  .navbar-collapse.collapsing {
    height: auto;
    -webkit-transition: left 0.3s ease;
    -o-transition: left 0.3s ease;
    -moz-transition: left 0.3s ease;
    transition: left 0.3s ease;
    left: -20.3em;
    height: 100vh;
  }

  .navbar-collapse.show {
    left: 0;
    -webkit-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    -moz-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
    z-index: 3;
  }

  .navbar-toggler {
    position: absolute;
    top: 10px !important;
    right: 3%;
  }
}

.m-menu {
  display: none;
}

.navbar-toggler {
  top: 27%;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-toggler span {
  display: block;
  background-color: #444;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  position: relative;
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  -webkit-transition: transform .35s ease-in-out;
  -moz-transition: transform .35s ease-in-out;
  -o-transition: transform .35s ease-in-out;
  transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 8px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 8px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.search-container {
  float: right;
}

.search-container input[type="text"] {
  padding: 6px;
  border: none;
  margin-top: 8px;
  margin-right: 16px;
  font-size: 17px;
  background-color: #03e1bc;
  color: #fff;
}

.search-container button {
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 27px;
  background: #ddd;
  font-size: 17px;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
}

@media screen and (max-width: 576px) {
  .m-d-none {
    display: none !important;
  }

  .header-btn {
    left: 0 !important;
    padding: 6% 5% 0 0%;
    top: 5px !important;
    background-color: transparent !important;
  }

  .navbar {
    padding: 0;
  }

  .navbar-top.d-flex.justify-content-between {
    height: unset;
  }

  [dir] .hero_section.height-half {
    background: none;
    margin-top: 63px;
  }

  .brand-logo {
    z-index: 2;
  }

  .m-menu .brand-logo {
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .hero_section.height-half {
    height: 47vh;
  }

  #google_translate_element {
    z-index: 999 !important;
    position: absolute !important;
    top: 12px !important;
    margin-left: 115px;
  }

  .m-menu select.sign_in {
    max-width: 100%;
  }

  .m-menu {
    display: block;
  }
  .sign_out{
    color: #03e1bc!important;
  }
  .search_input{
    margin-top: 0;
    width: 95%;
    height: calc(0.88em + 1.5rem + 2px);
    line-height: 1.88;
    color: #495057;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding:10px
  }
  .search_button{
    margin-left: -25px;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
  }
  .m-menu .navbar-nav .nav-link{
    margin: 0;
    padding:0;
  }
  .m-menu .nav-item {
    margin: 10px 0 0 0;
  }
  .m-menu  .nav-item a {
	  font-size: 20px;
  }
}

.brand-logo {
    width: 173px;
}

.brand-logo img {
    width: 100%;
}
</style>
